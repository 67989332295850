import * as React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

import { BannerPage } from '../components/BannerPage'
import { Container } from '../components/Container'
// import { Seo } from '../components/Seo'

const NotFoundPage = () => (
  <>
    {/* <Seo title="404: Not Found" description="" pathname={path} /> */}

    <BannerPage
      title="404: Not Found"
      description="You just hit a route that doesn&#39;t exist... You can use the search in the navigation bar to find the page you are looking for."
    />

    <Container>
      <StaticImage src="../../static/assets/pages/404.png" alt="" />
    </Container>
  </>
)

export default NotFoundPage
